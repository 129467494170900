@use 'src/styles/variables.scss';
@use 'src/styles/mixins.scss';
@use 'src/styles/functions.scss';

.quantity {
  display: flex;
  align-items: center;
  width: functions.rem(102);
  padding: functions.rem(3);
  border: functions.rem(1) solid variables.$main-grey;
  border-radius: functions.rem(7);

  @include mixins.tabletSmall {
    width: functions.rem(137);
    padding: functions.rem(5);
    border-radius: functions.rem(10);
  }
}

.button {
  flex-shrink: 0;
  width: functions.rem(25);
  height: functions.rem(27);
  border-radius: functions.rem(3);
  border: none;
  background-color: variables.$secondary-grey;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;

  @include mixins.tabletSmall {
    width: functions.rem(38);
    height: functions.rem(40);
    border-radius: functions.rem(4);
  }

  &.add {
    background-image: url('/assets/increase.svg');
  }

  &.subtract {
    background-image: url('/assets/decrease.svg');
  }

  &.disabled {
    pointer-events: none;
    opacity: .5;
  }
}

.input {
  width: 100%;
  height: functions.rem(27);
  line-height: functions.rem(27);
  margin: 0 functions.rem(3);
  font-weight: variables.$font-weight-semi-bold;
  font-family: variables.$font-family-primary;
  font-size: functions.rem(13);
  text-align: center;
  border: none;
  outline: none;

  @include mixins.tabletSmall {
    height: functions.rem(40);
    line-height: functions.rem(40);
    font-size: functions.rem(14);
  }
}