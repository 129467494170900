@use 'src/styles/variables.scss';
@use 'src/styles/mixins.scss';
@use 'src/styles/functions.scss';

@mixin openMenu($border-color: variables.$border-default) {
  box-shadow: 0 0 0 functions.rem(1) $border-color, 0 functions.rem(16) functions.rem(24) rgba(0, 0, 0, 0.09);
}

.wrapper {
  position: relative;
  padding-top: functions.rem(8);

  &.disabled {
    opacity: .5;
  }
}

.label {
  @include mixins.label();
}

.required {
  @include mixins.requiredMark();
}

.select {
  [class~="select__control"] {
    padding: 0 functions.rem(20);
    background-color: variables.$white;
    border-color: variables.$border-default;
    border-radius: functions.rem(10);
    min-height: functions.rem(50);
    line-height: functions.rem(14);

    &:hover {
      border-color: variables.$border-default;
    }
  }

  &.error {
    [class~="select__control"] {
      border-color: variables.$border-error;

      + [class~="select__menu"] {
        @include openMenu(variables.$border-error);
      }

      &:hover {
        border-color: variables.$border-error;
      }
    }
  }

  [class~="select__control--is-focused"] {
    border-color: variables.$border-focus;
    box-shadow: none;

    + [class~="select__menu"] {
      @include openMenu(variables.$border-focus);
    }

    &:hover {
      border-color: variables.$border-focus;
    }
  }

  [class~="select__control--menu-is-open"] {
    border-radius: functions.rem(10) functions.rem(10) 0 0;

    [class~="select__dropdown-indicator"] {
      transform: rotate(180deg);
    }
  }

  [class~="select__value-container"] {
    padding: 0 functions.rem(8) 0 0;
  }

  [class~="select__single-value"] * {
    margin-right: functions.rem(12);
  }

  [class~="select__placeholder"] {
    margin: 0;
  }

  [class~="select__input-container"] {
    margin: 0;
    padding: 0;
  }

  [class~="select__indicators"] {
    align-self: center;
    cursor: pointer;
  }

  [class~="select__indicator-separator"] {
    display: none;
  }

  [class~="select__dropdown-indicator"] {
    padding: 0;
    width: functions.rem(24);
    height: functions.rem(24);
    background: url('/assets/icons/select-arrow.svg') center no-repeat;

    svg {
      display: none;
    }
  }

  [class~="select__menu"] {
    z-index: variables.$zIndex-select-menu;
    left: functions.rem(1);
    right: functions.rem(1);
    width: auto;
    border-radius: 0 0 functions.rem(10) functions.rem(10);
    @include openMenu();
    margin: 0;
    overflow: hidden;
  }

  [class~="select__menu-list"] {
    overflow: hidden;
  }

  [class~="select__option"] {
    display: flex;
    align-items: center;
    padding: functions.rem(3) functions.rem(19);
    background-color: transparent;
    cursor: pointer;

    @include mixins.tabletSmall {
      padding-top: functions.rem(12);
      padding-bottom: functions.rem(12);
      font-size: functions.rem(11);
    }
  }

  [class~="select__option--is-focused"] {
    background-color: variables.$main-grey;
  }

  [class~="select__option--is-selected"] {
    font-weight: variables.$font-weight-semi-bold;
    color: variables.$text;
    background-color: variables.$main-grey;
  }
}
