@use 'src/styles/variables.scss';
@use 'src/styles/mixins.scss';
@use 'src/styles/functions.scss';

.image {
  flex-shrink: 0;
  width: functions.rem(52);
  margin-right: functions.rem(28);

  @include mixins.tabletSmall {
    width: functions.rem(60);
    margin-right: 0;
  }

  img {
    display: block;
    max-width: 100%;
    object-fit: contain;
  }
}

.label {
  font-weight: variables.$font-weight-semi-bold;
  font-size: functions.rem(11);
  font-family: variables.$font-family-secondary;
  line-height: functions.rem(13);

  @include mixins.tabletSmall {
    display: none;
  }
}
