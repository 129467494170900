@use 'src/styles/variables.scss';
@use 'src/styles/mixins.scss';
@use 'src/styles/functions.scss';

.loaderBlock {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  z-index: variables.$zIndex-loaderBlock;
}

.assemblyLength {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: functions.rem(69);

  .switchWrapper {
    margin-top: functions.rem(7);
  }

  @include mixins.tabletSmall {
    flex-direction: column;
    align-items: stretch;
    margin-bottom: functions.rem(40);
  }
}

.length {
  width: functions.rem(172);
  padding: 0;
  margin-right: functions.rem(10);

  @include mixins.tabletSmall {
    width: 100%;
    margin-bottom: functions.rem(20);
  }
}

.images {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: functions.rem(73);

  @include mixins.tabletSmall {
    flex-direction: column;
    align-items: stretch;
  }
}

.image {
  margin-right: functions.rem(23);

  @include mixins.tabletSmall {
    margin-right: 0;

    &:nth-child(2) {
      margin-bottom: functions.rem(30);
    }
  }

  &:first-child {
    margin-bottom: functions.rem(4);

    @include mixins.tabletSmall {
      margin-bottom: functions.rem(47);
    }
  }

  &:last-child {
    margin-right: 0;
  }

  img {
    max-width: 100%;

    @include mixins.tabletSmall {
      width: 100%;
    }
  }
}