@use './variables';
@use './mixins';
@use './functions';

// @import-normalize;

@import "@csstools/normalize.css";

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Roboto:wght@400;500;700&display=swap');

@import "print";

html {
  box-sizing: border-box;
  line-height: 1.47;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-weight: variables.$font-weight-regular;
  font-size: functions.rem(12);
  font-family: variables.$font-family-primary;
}

h1 {
  @include mixins.h1();
  text-align: center;
  color: variables.$primary;
}

h2 {
  @include mixins.h2();
}

h3 {
  @include mixins.h3();
}

h4 {
  @include mixins.h4();
}

h5 {
  @include mixins.h5();
}