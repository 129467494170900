@use 'src/styles/variables';
@use 'src/styles/mixins';
@use 'src/styles/functions';

.radioButton {
  @include mixins.radioButton();

  margin-bottom: functions.rem(12);
}

.description {
  opacity: .78;
  line-height: functions.rem(18);
}

.length {
  font-family: variables.$font-family-secondary;
  font-weight: variables.$font-weight-semi-bold;
}