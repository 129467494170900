@use 'src/styles/variables.scss';
@use 'src/styles/mixins.scss';
@use 'src/styles/functions.scss';

.wrapper {
  position: relative;
  padding-top: functions.rem(7);

  &.disabled {
    pointer-events: none;
    opacity: .5;
  }
}

.input {
  display: block;
  width: 100%;
  padding: 0 functions.rem(20);
  height: functions.rem(50);
  font-size: functions.rem(12);
  line-height: functions.rem(50);
  background-color: variables.$white;
  border: functions.rem(1) solid variables.$border-default;
  outline: none;
  border-radius: functions.rem(10);

  &:focus {
    border-color: variables.$border-focus;
  }

  &.error {
    border-color: variables.$border-error;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }
}

.label {
  @include mixins.label();
}

.required {
  @include mixins.requiredMark();
}