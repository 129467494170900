@use 'src/styles/variables.scss';
@use 'src/styles/mixins.scss';
@use 'src/styles/functions.scss';

.image {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: functions.rem(22);
  width: functions.rem(45);
  background: variables.$white;
  border: functions.rem(1) solid rgba(0, 0, 0, .1);
  border-radius: functions.rem(2);

  @include mixins.tabletSmall {
    margin-right: functions.rem(16);
    width: functions.rem(35);
  }

  img {
    max-width: 100%;
    object-fit: contain;
  }

  [class~="select__value-container"] & {
    display: none;
  }
}

.content {
  flex-grow: 1;
  display: grid;
  column-gap: 50px;
  align-items: center;

  &.content2 {
    grid-template-columns: 1fr auto;
  }
  &.content3 {
    grid-template-columns: 1fr auto auto;
  }

  @include mixins.tabletSmall {
    grid-template-columns: auto !important;
  }
}

.more {
  margin-left: 10px;
  text-align: right;

  @include mixins.tabletSmall {
    margin-left: 0;
    text-align: left;
  }

  [class~="select__value-container"] & {
    display: none;
  }
}
