@use 'src/styles/variables.scss';
@use 'src/styles/mixins.scss';
@use 'src/styles/functions.scss';

.quantityLabel {
  @include mixins.h4();
  font-size: functions.rem(12);

  display: flex;
  align-items: center;
  margin-right: functions.rem(15);

  .iconTooltip {
    margin-left: functions.rem(6);
  }

  @include mixins.tabletSmall {
    font-size: functions.rem(12);
    line-height: functions.rem(16);
  }
}

.required {
  @include mixins.requiredMark();
}
