@use 'src/styles/variables.scss';
@use 'src/styles/mixins.scss';
@use 'src/styles/functions.scss';

.trackVertical {
  right: functions.rem(4);
  bottom: 2px;
  top: 2px;
  width: functions.rem(4)!important;
}

.thumbVertical {
  background-color: variables.$scrollbar-track;
  border-radius: functions.rem(4);
}