@use 'src/styles/variables.scss';
@use 'src/styles/mixins.scss';
@use 'src/styles/functions.scss';

.tooltip {
  position: relative;
}

.icon {
  display: block;
}

.content {
  @include mixins.h5();

  max-width: functions.rem(226);
  padding: 0;
  background-color: variables.$white;
  opacity: 1;
  
  [class~="rc-tooltip-inner"] {
    padding: functions.rem(16) functions.rem(12);
    color: inherit;
    border: none;
    border-radius: functions.rem(11);
    box-shadow: 0 functions.rem(3) functions.rem(14) rgba(0, 0, 0, 0.09);
  }

  [class~="rc-tooltip-arrow"] {
    bottom: functions.rem(-8);
    width: functions.rem(13);
    height: functions.rem(11);
    margin-left: 0;
    border: none;
    background: url('/assets/icons/tooltip-arrow.svg') center no-repeat;
  }

  &[class~="rc-tooltip-placement-topLeft"] {
    [class~="rc-tooltip-arrow"] {
      left: 12%;
      transform: translateX(functions.rem(-1));
    }
  }

  &[class~="rc-tooltip-placement-topRight"] {
    [class~="rc-tooltip-arrow"] {
      right: 10%;
      transform: translateX(functions.rem(1));
    }
  }
}