@use 'src/styles/variables.scss';
@use 'src/styles/mixins.scss';
@use 'src/styles/functions.scss';

.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;

  .form {
    position: relative;
    height: auto;
    min-height: 250px; // FIXME ?
    border-radius: functions.rem(variables.$border-radius);
    background-color: variables.$white;
    padding: functions.rem(35) functions.rem(100) functions.rem(30);
    width: 100%;
    max-width: 900px;

    @include mixins.heightSmall {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }

    @include mixins.tablet {
      padding-left: functions.rem(40);
      padding-right: functions.rem(40);
    }

    @include mixins.tabletSmall {
      padding: 0;
      background-color: transparent;
    }


  }
}

.content {
  @include mixins.tabletSmall {
    padding: functions.rem(19) functions.rem(25) functions.rem(26);
    background-color: variables.$white;
    border-radius: functions.rem(variables.$border-radius);
    box-shadow: 0 functions.rem(4) functions.rem(33) rgba(0, 0, 0, 0.06);
  }
}

.title {
  margin: 0 0 functions.rem(11);

  @include mixins.tabletSmall {
    text-align: left;
  }
}

.description {
  max-width: functions.rem(500);
  margin: 0 auto functions.rem(25);
  text-align: center;

  @include mixins.tabletSmall {
    margin-left: 0;
    margin-right: 0;
    text-align: left;
  }
}
