@use 'src/styles/functions.scss';
@use 'src/styles/mixins.scss';


.angleOfRotationGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 functions.rem(35);
  margin-top: functions.rem(42);

  @include mixins.tabletSmall {
    flex-flow: column;
    padding: 0;
    margin-top: functions.rem(29);
  }

  .select {
    width: functions.rem(165);
    margin-bottom: functions.rem(45);
    margin-right: functions.rem(45);

    @include mixins.tabletSmall {
      margin: 0 0 functions.rem(36) 0;
      width: 100%;
    }
  }
}