@use 'src/styles/variables.scss';
@use 'src/styles/mixins.scss';
@use 'src/styles/functions.scss';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: functions.rem(165);
  padding: functions.rem(18) functions.rem(29) functions.rem(16);
  font-weight: variables.$font-weight-medium;
  font-size: functions.rem(14);
  border-radius: functions.rem(10);
  border: none;
  cursor: pointer;
  transition: all .2s ease-out;

  &.primary {
    color: #fff;
    background-color: variables.$primary;

    &:hover {
      @include mixins.hovered();
    }

    &:active {
      background-color: variables.$primary-active;
      box-shadow: none;
    }
  }

  &.secondary {
    color: variables.$primary;
    background-color: variables.$secondary;

    &:hover {
      @include mixins.hovered(variables.$secondary-hover, variables.$secondary-hover-shadow);
    }

    &:active {
      background-color: variables.$secondary-active;
      box-shadow: none;
    }
  }

  &.transparent {
    color: variables.$primary;
    background: transparent;

    &:active {
      color: variables.$primary-active;
    }

    span {
      text-decoration: underline;
    }

    svg {
      margin-right: 5px;
    }
  }

  &.disabled {
    pointer-events: none;

    &.primary {
      color: variables.$primary-disabled-font;
      background-color: variables.$primary-disabled;
    }
    &.secondary {
      color: variables.$secondary-disabled-font;
      background-color: variables.$secondary-disabled;
    }
  }
}

.full {
  width: 100%;
}
