@use 'src/styles/variables.scss';
@use 'src/styles/mixins.scss';
@use 'src/styles/functions.scss';

.input {
  display: none;
}

.checkbox {
  @include mixins.h2();
  cursor: pointer;
  display: flex;
  align-items: center;
  color: variables.$checkbox-text;

  &.disabled {
    pointer-events: none;
    opacity: .5;
  }

  &.error {
    // color: variables.$red;

    &::before {
      border-color: variables.$red;
    }
  }

  &::before {
    content: "";
    flex-shrink: 0;
    display: block;
    margin-right: functions.rem(15);
    width: functions.rem(18);
    height: functions.rem(18);
    border: functions.rem(1) solid variables.$checkbox-border;
    border-radius: functions.rem(2);

    .input:checked + & {
      background: variables.$checkbox-select url('/assets/icons/check-white.svg') center no-repeat;
      border-color: variables.$checkbox-select;
    }
  }
}

.description {
  margin: functions.rem(12) 0 0;
  opacity: .78;
}

.errorMessage {
  margin: functions.rem(12) 0 0;
  opacity: .78;
  color: variables.$red;
}

.image {
  flex-shrink: 0;
  margin-right: functions.rem(23);
  width: functions.rem(90);
  height: functions.rem(75);
  border: functions.rem(1) solid rgba(0, 0, 0, 0.16);
  border-radius: functions.rem(6);
  object-fit: contain;

  @include mixins.tabletSmall {
    margin-right: 0;
    margin-bottom: functions.rem(18);
  }
}

.wrapper {
  display: flex;
  align-items: center;

  @include mixins.tabletSmall {
    flex-direction: column;
    align-items: flex-start;
  }
}
