/* FONT styles */
$font-family-primary: 'Roboto', sans-serif;
$font-family-secondary: 'Inter', sans-serif;

$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

/* colors */
$accent: #0075BF;
$text: #000000;
$bgc: #FAFAFA;
$white: #FFFFFF;
$main-grey: #EBEBEB;
$secondary-grey: #EEF0F2;
$dark-grey: #888888;
$red: #E35E5E;

$step-inactive: $main-grey;
$step-line: #ECECEC;

$scrollbar-track: #d6d6dd;

$primary: $accent;
$primary-hover: #0269AA;
$primary-hover-shadow: rgba(0, 117, 191, 0.5);
$primary-active: #03619C;
$primary-disabled: rgba(#6E8B9D, .3);
$primary-disabled-font: $white;

$secondary: $secondary-grey;
$secondary-hover: #DFE8F0;
$secondary-hover-shadow: $secondary-grey;
$secondary-active: #CAD7E3;
$secondary-disabled: #F9FAFB;
$secondary-disabled-font: #B2D5EB;

$border-default: $main-grey;
$border-focus: $primary;
$border-error: $red;
$border-radius: 10;


$checkbox-border: #BEBEBE;
$checkbox-text: #1F1F1F;
$checkbox-select: #2BB483;

$cardPaddingH: 19;
$cardPaddingTabletH: 15;
$cardPaddingMobileH: 18;

$switch-shadow: #0A5382;

$indicatorTrack: #F8F7F7;
$indicatorProgress: $primary;

$offset-container: 25;

// $z-index
$zIndex-label: 1;
$zIndex-select-menu: 2;
$zIndex-navigation: 3;
$zIndex-loaderBlock: 10;
