@use 'src/styles/variables.scss';
@use 'src/styles/mixins.scss';
@use 'src/styles/functions.scss';

.wrapper {
  display: flex;
  align-items: center;

  @include mixins.tabletSmall {
    flex-direction: column;
    align-items: flex-start;
  }
}

.image {
  flex-shrink: 0;
  margin-right: functions.rem(23);
  width: functions.rem(90);
  height: functions.rem(75);
  border: functions.rem(1) solid rgba(0, 0, 0, 0.16);
  border-radius: functions.rem(6);
  object-fit: contain;

  @include mixins.tabletSmall {
    margin-right: 0;
    margin-bottom: functions.rem(18);
  }
}

.input {
  display: none;
}

.radioButton {
  @include mixins.radioButton();
}
