@use 'src/styles/variables.scss';
@use 'src/styles/mixins.scss';
@use 'src/styles/functions.scss';

.secondaryNav {
  position: sticky;
  top: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;

  @include mixins.heightSmall {
    gap: 0;
  }
}
