@use 'src/styles/variables.scss';
@use 'src/styles/mixins.scss';
@use 'src/styles/functions.scss';

.input {
  display: none;
}

.switch {
  display: inline-flex;
  align-items: center;
  min-width: functions.rem(172);
  padding: functions.rem(4);
  cursor: pointer;
  border: functions.rem(1) solid variables.$border-default;
  border-radius: functions.rem(variables.$border-radius);

  @include mixins.mobile {
    display: flex;
    min-width: initial;
  }
}

.label {
  @include mixins.h2();

  flex: 1;
  padding: functions.rem(12) functions.rem(5);
  opacity: .34;
  text-align: center;
  border-radius: functions.rem(6);

  &.active {
    color: variables.$accent;
    background: variables.$secondary-grey;
    opacity: 1;
  }
}