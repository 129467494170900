@use 'src/styles/variables.scss';
@use 'src/styles/mixins.scss';
@use 'src/styles/functions.scss';

.wrapper {
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-top: functions.rem(50);

  &.singleButton {
    justify-content: flex-end;
  }

  @include mixins.tabletSmall() {
    flex-flow: column-reverse;
    justify-content: stretch;
    align-items: center;
    margin-top: functions.rem(25);
  }

  @include mixins.heightSmall {
    margin-top: functions.rem(20);
  }
}

.backAndReset {
  display: flex;
  flex-flow: row;
  justify-content: space-between;

  @include mixins.tabletSmall() {
    width: 100%;
    flex-flow: column;
    justify-content: stretch;
    align-items: center;
  }
}

.button {
  @include mixins.tabletSmall() {
    margin-top: functions.rem(10);
    width: 100%;
  }
}

.buttonReset {
  @include mixins.tabletSmall() {
    margin-top: functions.rem(10);
    width: 100%;
  }

  svg {
    transform-origin: 11px 11px;
  }

  &:hover {
    svg {
      animation: spin 0.5s ease-in-out 1;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
