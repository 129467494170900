@use 'src/styles/variables.scss';
@use 'src/styles/mixins.scss';
@use 'src/styles/functions.scss';

@mixin line  {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: functions.rem(4);
  transform: translateY(functions.rem(4));
  background-color: variables.$primary;
}

.steps {
  display: flex;
  width: 100%;
  margin: 0 auto functions.rem(28);
  border-bottom: 1px solid #E1E1E1;

  @include mixins.tabletSmall {
    padding-left: functions.rem(variables.$offset-container);
    padding-right: functions.rem(variables.$offset-container);
    border-bottom: none;
  }

  @include mixins.heightSmall {
    margin-bottom: functions.rem(20);
  }
}

.step {
  position: relative;
  flex: 1 1;
  padding: functions.rem(7) 0 functions.rem(8);
  color: rgba(variables.$text, .41);
  font-size: functions.rem(12);
  font-family: variables.$font-family-primary;
  text-align: center;

  @include mixins.tabletSmall {
    margin-right: functions.rem(9);
    padding: functions.rem(15) 0 functions.rem(4);
    font-size: 0;
  }

  &:last-child {
    @include mixins.tabletSmall {
      margin-right: 0;
    }
  }

  &::after {
    @include mixins.tabletSmall {
      @include line;
      background-color: variables.$step-line;
    }
  }

  &.current {
    font-weight: variables.$font-weight-semi-bold;
    color: variables.$primary;

    &::after {
      @include line;
    }

    @include mixins.tabletSmall {
      flex: 2;
      font-size: functions.rem(12);
    }

    + .step {
      cursor: pointer;
    }
  }

  &.complete {
    color: variables.$primary;
    cursor: pointer;

    &::after {
      @include line;
    }
  }
}

.text {
  @include mixins.tabletSmall {
    white-space: nowrap;
  }
}
