@use 'src/styles/variables.scss';
@use 'src/styles/mixins.scss';
@use 'src/styles/functions.scss';

.item {
  margin-bottom: functions.rem(20);

  @include mixins.tabletSmall {
    margin-bottom: functions.rem(25);
  }

  &:last-child {
    margin-bottom: 0;
  }
}
